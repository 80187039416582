import React from "react";
import styled from "styled-components";
import { Row } from "antd";

const Container = (props) => {
  return <StyledContainer {...props}>{props.children}</StyledContainer>;
};

export default Container;

const StyledContainer = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 37px;
  padding: 40px 35px 40px 35px;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;

  @media (max-width: 1024px) {
    padding: 20px;
    margin-bottom: 22px;
  }
`;
