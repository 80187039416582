import { TimePicker } from "antd";
import moment from "moment";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";

import TouchableOpacity, {
  mem,
  View
} from "../../shared/custom-react-native";
// import CustomDatatable from "../_components/CustomDatatable";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed, Success } from "../_components/Modal";
import "./css.css";
import { isMobileScreen } from "../../constants";

const context = "onboard";

export default class OnboardAvailability extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Availability",
      is_drawer_open: false,
      availability_slots: [
        { day: "Sunday", slots: [], isDisabled: false },
        { day: "Monday", slots: [], isDisabled: false },
        { day: "Tuesday", slots: [], isDisabled: false },
        { day: "Wednesday", slots: [], isDisabled: false },
        { day: "Thursday", slots: [], isDisabled: false },
        { day: "Friday", slots: [], isDisabled: false },
        { day: "Saturday", slots: [], isDisabled: false },
      ]
    });

  }

  onCreate = () => {
    const data = mem.get("user_doctor");
    const user_doctor = JSON.parse(data);
    let slots = user_doctor.slots
    const clone_availability_slots = this.state.availability_slots

    clone_availability_slots.map(a => {
      slots.map(b => {
        if (a.day === b.day) {
          a.slots.push(b)
        }
        return b
      })
      return a
    })

    this.setState({
      availability_slots: clone_availability_slots
    })
  }

  get24HoursFormat = (timeString) => {
    return moment(timeString, ["h:mm a"]).format("HH:mm");
  }

  setDefaultValueTime = (slots, day, start, index) => {
    if (slots) {
      if (slots.length > 0) {
        let res = slots.filter(slot => slot?.day?.toLowerCase() === day?.toLowerCase())

        if (res.length > 0) {
          const m = moment(new Date());
          if (start === "Start") {
            if (res[index].startTime) {
              const minutes = res[index].startTime.split(":")[1];
              const hourFomatTo24Hours = res[index].startTime.slice(0, 2);
              m.set({ h: hourFomatTo24Hours, m: parseFloat(minutes) });
              return moment(m);
            }
          }
          if (start === "End") {
            if (res[index]?.endTime) {
              const minutes = res[index].endTime.split(":")[1];
              const hourFomatTo24Hours = res[index].endTime.slice(0, 2);
              m.set({ h: parseFloat(hourFomatTo24Hours), m: parseFloat(minutes) });
              return moment(m);
            }
          }
        }
      }
    }

    return "";
  }

  isTimeRangeWithin(timeRange, withinRange) {
    const startTime1 = moment(timeRange.start, 'hh:mm A');
    const endTime1 = moment(timeRange.end, 'hh:mm A');

    const startTime2 = moment(withinRange.start, 'hh:mm A');
    const endTime2 = moment(withinRange.end, 'hh:mm A');

    return ((startTime1.isSameOrAfter(startTime2) && startTime1.isSameOrBefore(endTime2)) || (startTime2.isSameOrAfter(startTime1) && startTime2.isSameOrBefore(endTime1)));
  }

  showModalError = () => {

    this.show_modal(
      <Failed
        title={"ERROR"}
        description={"Timeslot already chosen, set another schedule"}
        onDismiss={() => {
          this.hide_modal();
        }}
        onDismissText={"OK"}
      />
    )

    return true;
  }

  checkOverlapSchedule = (schedule, index) => {
    for (const slot of this.state.availability_slots[index].slots) {

      const range = schedule.length > 1;

      // Checking if the schedule is range time
      if (range) {
        const range1 = { start: moment(schedule[0], 'hh:mm A'), end: moment(schedule[1], 'hh:mm A') };
        const range2 = { start: moment(slot.startTime, 'hh:mm A'), end: moment(slot.endTime, 'hh:mm A') };

        if (this.isTimeRangeWithin(range1, range2)) {
          return this.showModalError();
        }
      } else {
        const startTime = moment(moment(slot.startTime, 'hh:mm A'));
        const endTime = moment(moment(slot.endTime, 'hh:mm A'));

        if (schedule[0].isBetween(startTime, endTime)) {
          return this.showModalError();
        }
      }
    }
    return false;
  };

  onChangeTime = (time, timeString, slot, index, start) => {

    for (const [indexSlot, item] of this.state.availability_slots.entries()) {
      item.slots.forEach((b, i) => {
        if (index === i && slot.day === item.day) {
          if (start === "Start") {
            if (timeString) {

              if (this.state.availability_slots[indexSlot].slots.length > 1) {
                const schedule = [time]
                b.overlap = this.checkOverlapSchedule(schedule, indexSlot);
              }

              b.startMomentTime = time

              if (b.endTime) {
                if (this.state.availability_slots[indexSlot].slots.length > 1) {
                  const schedule = [b.startMomentTime, b.endMomentTime]
                  b.overlap = this.checkOverlapSchedule(schedule, indexSlot);
                }
              }

              if (b.overlap) {
                b.startTime = "";
                b.startMomentTime = "";
                b.overlap = false
                return;
              }

              b.startTime = this.get24HoursFormat(timeString)

            } else {
              b.startTime = ""
              b.startMomentTime = ""
            }

          } else {

            if (timeString) {

              if (this.state.availability_slots[indexSlot].slots.length > 1) {
                const schedule = [time]
                b.overlap = this.checkOverlapSchedule(schedule, indexSlot);
              }

              b.endMomentTime = time

              if (b.startTime && this.state.availability_slots[indexSlot].slots.length > 1) {
                const schedule = [b.startMomentTime, b.endMomentTime]
                b.overlap = this.checkOverlapSchedule(schedule, indexSlot);
              }

              if (b.overlap) {
                b.endTime = "";
                b.endMomentTime = "";
                b.overlap = false
                return;
              }
              b.endTime = this.get24HoursFormat(timeString)

            } else {
              b.endTime = ""
              b.endMomentTime = ""
            }
          }
        }

        if (b.startTime && b.endTime) {
          item.isDisabled = false
        } else {
          item.isDisabled = true
        }
      })
    }

    const new_slots = this.state.availability_slots

    this.setState({ availability_slots: new_slots })
  }

  addNewSlot = (res) => {
    for (const a of this.state.availability_slots) {
      for (const [
        // b, 
        c] of a.slots.entries()) {
        if (res.day === c.day) {

          if ((!c.startTime && !c.endTime) || (c.startTime && !c.endTime) || (!c.startTime && c.endTime)) {
            return true
            // this.show_modal(
            //   <Failed
            //     title={"Failed"}
            //     description={`Fill out the schedule ${b + 1} time before adding new one.`}
            //     onDismiss={() => {
            //       this.hide_modal();
            //     }}
            //   />
            // );
          }
        }
      }
    }

    const slots = this.state.availability_slots.map(item => {
      if (item.day === res.day) {
        item.slots.push({
          day: res.day,
          startTime: "", endTime: ""
        })
      }

      item.slots.map(b => {
        if (!b.startTime && !b.endTime) {
          item.isDisabled = true
        }
        if (b.startTime && !b.endTime) {
          item.isDisabled = true
        }
        if (!b.startTime && b.endTime) {
          item.isDisabled = true
        }
        return b
      })

      return item
    })

    this.setState({ availability_slots: slots })
  }

  removeSlot = (res, index, i) => {
    const clone = this.state.availability_slots
    const slots = clone[i].slots.filter((a, b) => index !== b)
    clone[i].slots = slots
    res.isDisabled = false
    this.setState({ availability_slots: clone })
  }

  submitTimeAvailibity = () => {

    for (const item of this.state.availability_slots) {
      for (const item_1 of item.slots) {
        if (item_1.startTime && !item_1.endTime) {
          return this.show_modal(
            <Failed
              title={"Failed"}
              description={`Monday end time schedule is required.`}
              onDismiss={() => {
                this.hide_modal();
              }}
            />
          );
        }
        if (!item_1.startTime && item_1.endTime) {
          console.log(item_1.startTime, item_1.endTime.slice(3, 5))
          return this.show_modal(
            <Failed
              title={"Failed"}
              description={`Monday start time schedule is required.`}
              onDismiss={() => {
                this.hide_modal();
              }}
            />
          );
        }

        // if (!["30", "00"].includes(item_1.endTime.slice(3, 5)) || !["30", "00"].includes(item_1.startTime.slice(3, 5))) {
        //   return this.show_modal(
        //     <Failed
        //       title={"Failed"}
        //       description={`Invalid Time Format Minutes .`}
        //       onDismiss={() => {
        //         this.hide_modal();
        //       }}
        //     />
        //   );
        // }


        let validate = item.slots.find((row, index) => {
          if (moment(row.startTime, ["HH:mm"]).format("a") === "pm" && moment(row.endTime, ["HH:mm"]).format("a") === "pm") {
            if (row.endTime === "12:00") return true;
            if (row.startTime >= row.endTime) return true;
          }

          if (moment(row.startTime, ["HH:mm"]).format("a") === "am" && moment(row.endTime, ["HH:mm"]).format("a") === "am") {
            if (row.endTime === "00:00") return true;
            if (row.startTime >= row.endTime) return true;
          }

          if (row.startTime >= row.endTime) {
            if (moment(row.startTime, ["HH:mm"]).format("a") === "am") {
              if (row.endTime === "12:00") {
                return false;
              } else {
                return true;
              }
            }

            if (moment(row.startTime, ["HH:mm"]).format("a") === "pm") {
              if (row.endTime === "00:00") {
                return false;
              } else {
                return true;
              }
            }
          }
          return "";
          // return true;
        })

        if (validate) {
          if (validate.startTime !== "" && validate.endTime !== "")
            return this.show_modal(
              <Failed
                title={"Failed"}
                // description={`Invalid time format on ${UI.capitalize(validate.day)}`}
                description={`Sorry! It seems that your schedules are overlapping. Kindly check.`}
                onDismiss={() => {
                  this.hide_modal();
                }}
              />
            );

          // if (validate.startTime.includes(''))
        }

      }
    }

    // CONTINUE IF NO VALIDATION OCCURED

    let final_slots = []

    let new_slot = this.state.availability_slots;
    new_slot.forEach(res => {
      const clone_slots = res.slots

      clone_slots.map(row => {
        const time_format = moment(row.startTime, 'HH:mm').tz('Etc/GMT').format('HH:mm a').slice(6, 8)
        row.gmtStartTime = moment(row.startTime, 'HH:mm').tz('Etc/GMT').format('HH:mm')
        row.gmtEndDate = moment(row.startTime, 'HH:mm').tz('Etc/GMT').format('HH:mm')
        row.session = time_format === "am" ? "afternoon" : "morning"
        row.gmtStartDate = moment(row.startTime, 'HH:mm').tz('Etc/GMT').format('HH:mm')
        row.gmtEndTime = moment(row.endTime, 'HH:mm').tz('Etc/GMT').format('HH:mm')

        row.startDateGMTTimestamp = moment(row.startTime, 'HH:mm');
        row.endDateGMTTimestamp = moment(row.endTime, 'HH:mm');
        row.slotInterval = 30
        return row
      })
      const concat_slots = [].concat.apply([], clone_slots);
      final_slots = [...final_slots, ...concat_slots]
      return res
    })

    this.show_loading();
    UI.webRequest({
      method: "post",
      url: "doctors_onboarding/doctors/slots",
      params: {
        slots: final_slots
      },
      onFail: async (err) => {
        const data = err.data;
        const error_message = data.m;
        // console.log(data)
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: async (response) => {
        // const data = UI.extract_data(response);
        this.show_modal(
          <Success
            title={"Success!"}
            descriptionCenter={"Updated successfully"}
            onDismiss={() => {
              this.hide_modal();
            }}
          />)
      },
      onFinish: () => {
        this.hide_loading();
      },
    });

  }

  render() {
    const _this = this;
    const headerBgColor = 'rgb(234, 233, 240)';

    return (
      <MainLayout context={context} _this={_this}>
        <View>
          <UI.Row _this={_this} breakpoint={950} style={{ width: "100%", padding: "20px 20px 10px 10px" }}>
            <View style={{ flex: 1 }}>
              <span className="font-semibold" style={{ color: UI.colors.secondary, fontSize: 18 }}>Set Doctor's Availability</span>
            </View>
            <View>
              {/* <span className="font-semibold" style={{color: UI.colors.unset, fontSize: 18}}>Set advocacy start/end time per day</span> */}
            </View>
          </UI.Row>

          <View id="availability_schedule" style={{ padding: "10px 10px", backgroundColor: 'white' }}>
            <UI.Row style={{ padding: "0px", gap: 20 }} breakpoint={1053} _this={_this}>
              {
                this.state.availability_slots?.map((res, i) => {
                  return (
                    <View className="mt-2 lg:mt-0 flex-1">
                      <span className={`font-semibold ${!res.slots.length && `${isMobileScreen ? "mb-4" : "mb-8"}`}`} style={{ fontSize: 16, paddingBottom: 10, backgroundColor: headerBgColor}}>{res.day}</span>
                      {
                        res.slots.map((slot, index) => {
                          return (
                            <>
                              <div className="flex justify-between items-center">
                                <span className="font-bold" style={{ color: UI.colors.secondary }}> Schedule {index + 1} </span>

                                <TouchableOpacity onClick={() => {
                                  this.removeSlot(res, index, i)
                                }}>
                                  <img src={UI.CLOSE_GRAY} height={10} width={10} alt="" />
                                </TouchableOpacity>

                              </div>
                              {UI.box(10)}
                              <TimePicker
                                status="warning"
                                value={this.setDefaultValueTime(res.slots, slot.day, 'Start', index)}
                                minuteStep={30}
                                use12Hours
                                format="h:mm a"
                                placeholder="Start Time"
                                onChange={(time, timeString) => {
                                  this.onChangeTime(time, timeString, res, index, 'Start');
                                }}
                              />
                              {UI.box(10)}
                              <TimePicker
                                value={this.setDefaultValueTime(res.slots, slot.day, 'End', index)}
                                status="warning"
                                minuteStep={30}
                                use12Hours
                                format="h:mm a"
                                placeholder="End Time"
                                onChange={(time, timeString) => {
                                  this.onChangeTime(time, timeString, res, index, 'End');
                                }}
                              />
                              {UI.box(10)}

                            </>)
                        })
                      }
                      <TouchableOpacity style={{
                        flexDirection: 'row', borderRadius: 8, justifyContent: 'flex-start',
                        cursor: res.isDisabled ? 'not-allowed' : 'pointer',
                        background: res.isDisabled ? 'rgb(0 79 153 / 44%)' : UI.colors.secondary
                      }}
                        onClick={() => {
                          this.addNewSlot(res)
                        }}
                      >
                        <div className="flex flex-row">
                          <div className='my-3 flex items-center gap-2  rounded-md font-semibold px-4'>
                            <img height={15} width={15} src={UI.PLUS} alt="" />
                            <strong className='text-white' style={{ fontSize: '15px' }}>Add New</strong>
                          </div>
                        </div>
                      </TouchableOpacity>
                    </View>
                  )
                })
              }
            </UI.Row>
          </View>

          <div className="px-2 sm:w-60 my-5 lg:my-0">
            <Layout.Btn
              style={{ padding: '5px 35px', width: "100%" }}
              color={UI.colors.secondary}
              onClick={() => {
                this.submitTimeAvailibity();
              }}
              disable={true}
              text={"UPDATE SCHEDULE"}
              height={50}
            />
          </div>

        </View>
      </MainLayout>
    );
  }
}