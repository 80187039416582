export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const isMobileScreen = window.innerWidth < 600;
export const is1024Screen = window.innerWidth <= 1024;

export const doctorNameColor = "#535353";
export const fieldLabelColor = "#4fc2ed";
export const patientDetailHeaderColor = "#004f99";

export const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

//Exceptions: No same day booking and Sunday schedule.
//Schedule 6-2pm everyday, 8-11:30 am on saturdays.
export const availableLabAtHomeBookingTime = [
  { time: "6:00 am" },
  { time: "6:30 am" },
  { time: "7:00 am" },
  { time: "7:30 am" },
  { time: "8:00 am" },
  { time: "8:30 am" },
  { time: "9:00 am" },
  { time: "9:30 am" },
  { time: "10:00 am" },
  { time: "10:30 am" },
  { time: "11:00 am" },
  { time: "11:30 am" },
  { time: "1:00 pm" },
  { time: "1:30 pm" },
  { time: "2:00 pm" },
];
