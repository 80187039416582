import React from "react";
import styled from "styled-components";
import { Typography } from "antd";

const FieldLabel = ( props ) => {
  return <StyledFieldLabel {...props}>{props.children}</StyledFieldLabel>;
};

export default FieldLabel;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #55ccff;
  margin-bottom: 11px !important; //bypass antD 1em value
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500; // medium
  line-height: 20px; // 23px
  letter-spacing: 0px;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;
