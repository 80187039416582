//Images are stored in public folder
class Images {
  ADD_FILE = "/img/add_file.png";
  ADD_IMAGE = "/img/add_image.png";
  ADD_TEXT = "/img/add_text.png";
  AVAILABILITY = "/img/availability.png";
  AVAILABLE = "/img/available.png";
  BANNER_BASE = "/img/banner_base.png";
  DOCTOR_ADVOCACY_REGISTRATION_BANNER =
    "/img/doctor_advocacy_registration_banner.jpg";
  ADVOCACY_NMD_BANNER = "/img/advocacy_nmd_banner.png";
  DOCTOR_ADVOCACY_REGISTRATION_BANNER_MOBILE =
    "/img/doctor_advocacy_registration_banner_mobile.png";
  BANNER_BASE_2X = "/img/banner_base_2x.png";
  BANNER_BASE_VIEW = "/img/banner_base_view.png";
  BANNER_BASE_BLUE = "/img/banner_base_blue.png";
  DEFAULT_IMAGE = "/img/default_image.png";
  DEFAULT_USER = "/img/default_user.png";
  CALL_AUDIO_ACTIVE = "/img/call_audio_active.png";
  CALL_AUDIO_INACTIVE = "/img/call_audio_inactive.png";
  CALL_ENCOUNTER_NOTES = "/img/call_encounter_notes.png";
  CALL_ENDED = "/img/call_ended.png";
  CALL_NOTES = "/img/call_notes.png";
  CALL_NOTES_1 = "/img/call_notes1.png";
  CALL_VIDEO_ACTIVE = "/img/call_video_active.png";
  CALL_VIDEO_INACTIVE = "/img/call_video_inactive.png";
  ICON_DELETE = "/img/icon_delete.png";
  ICON_EDIT = "/img/icon_edit.png";
  MINIMIZE = "/img/minimize.png";
  DEFAULT_AVATAR_PATIENT = "/img/default_avatar_patient.png";
  DEFAULT_AVATAR_DOCTOR = "/img/default_avatar_doctor.png";
  DEFAULT_AVATAR_ESIGN = "/img/default_avatar_esign.png";
  PATIENT_NAME_CONTAINER = "/img/patient_name_container.png";
  VIEW_NOTES = "/img/view_notes.png";
  CONTAINER = "/img/container.png";
  CALENDAR_4 = "/img/calendar_4.png";
  LAB = "/img/lab.png";
  RISK_FACTOR = "/img/risk_factor.png";
  FILE_1 = "/img/file_1.png";
  FILE_2 = "/img/file_2.png";
  DROPDOWN = "/img/dropdown.png";
  DROPUP = "/img/dropup.png";
  MOVEDOWN = "/img/move_down.png";
  MOVEUP = "/img/move_up.png";
  DROPUP_1 = "/img/dropup_1.png";
  UPDATE_PEN = "/img/update.png";
  UPDATE_PEN_LG = "/img/update@2x.png";
  VIEW_MORE = "/img/view_more.png";
  UPLOAD_IMAGE = "/img/upload_image.png";
  ATTACH = "/img/attach.png";
  DOWNLOAD = "/img/download.png";
  LOGO = "/img/logo.png";
  LOGO_MWELL = "/img/logo_mWell.png";
  NO_VIDEO = "/img/no_video.png";
  WHITE_FADE = "/img/white_fade.png";

  LOGOUT = "/img/logout.png";
  BG = "/img/bg.png";
  BG_2 = "/img/bg_2.png";
  BG_LOGIN = "/img/gfx.png";
  EYE_OPENED = "/img/eye_opened.png";
  EYE_CLOSED = "/img/eye_closed.png";
  THICK_CHECK = "/img/thick_check.png";
  MALE_BG = "/img/male_bg.png";
  CALENDAR_1 = "/img/calendar_1.png";
  KEYS = "/img/keys.png";
  MWELL_LOGO = "/img/mwell_logo.png";
  DOCTOR_PROFILE = "/img/doctor_profile_placeholder.jpg";
  FOLDER = "/img/folder.png";
  CALENDAR_2 = "/img/calendar_2.png";
  METRO_WELL = "/img/metro_well.png";
  HOME = "/img/home.png";
  CALENDAR_3 = "/img/calendar_3.svg";
  HOME_1 = "/img/home_1.svg";
  AVAILABILITY_1 = "/img/availability_1.svg";
  CALENDAR_5 = "/img/calendar_5.svg";
  CALENDAR_6 = "/img/calendar_6.png";
  CALENDAR_7 = "/img/calendar_7.png";
  USER = "/img/user.png";
  FILE = "/img/file.png";
  LOCK = "/img/lock.png";
  LOGOUT = "/img/logout.svg";
  CAL = "/img/calendar.png";
  HOMES = "/img/homes.png";
  CHANGE = "/img/change-password.png";
  PASSWORD_ICON = "/img/password_icon.svg";
  PROFILE = "/img/user-profile.svg";
  BELL = "/img/bell.png";
  CURRENT = "/img/mwell-basic.png";
  HAND_HEART = "/img/hand_heart.png"
  DOC = "/img/mwell.png";
  MENU = "/img/menu.png";
  PAY = "/img/pay.png";
  EXPAND = "/img/expand.png";
  HIDE = "/img/hide.png";
  ARROW_LEFT = "/img/arrow_left.png";
  ARROW_RIGHT = "/img/arrow_right.png";
  LEFT_ARROW = "/img/left_arrow.png";
  RIGHT_ARROW = "/img/right_arrow.png";
  LEFT_ARROW_1 = "/img/left_arrow_1.png";
  RIGHT_ARROW_1 = "/img/right_arrow_1.png";
  PLUS = "/img/plus.png";
  MINUS = "/img/minus.png";
  CHECK = "/img/check.png";
  CHECK_SQUARE_FILL = "/img/check_square_fill.png";
  EDIT = "/img/edit.png";
  TRASH = "/img/trash.png";
  DELETE_BTN = "/img/delete_btn.png";
  X_SQUARE_FILL = "/img/x_square_fill.png";
  EDIT_BTN = "/img/edit_btn.png";
  SEARCH = "/img/search.png";
  NO_INFO = "/img/no_info.png";
  SAMPLE_USER = "/img/sample_user.png";
  ADMIN_LOGIN = "/img/admin_login.png";
  ON = "/img/on.png";
  OFF = "/img/off.png";
  JOIN = "/img/join.png";

  GPLAY = "/img/gplay.png";
  APPSTORE = "/img/app_store.png";
  FACEBOOK = "/img/facebook.png";
  LINKEDIN = "/img/linkedin.png";
  TWITTER = "/img/twitter.png";
  INSTAGRAM = "/img/instagram.png";
  ADVOCACY_DOCTOR = "/img/advocacy_doctor.png";
  LOGO_ADVOCACY = "/img/logo_advocacy.png";
  ADVOCACY_LOGO_HOMEPAGE = "/img/adocacy_logo_homepage.png";

  SIGNUP_IMAGE = "/img/signup_image.jpg";
  SIGNIN_IMAGE = "/img/signin_image.jpg";

  BG_SIGNUP = "/img/bg_signup.png";
  BG_SIGNIN = "/img/bg_signin.png";
  BG_SIGNIN = "/img/bg_signin.png";
  LOGIN_BG = "/img/login_bg.png";
  LOGIN_LOGO_MWELL = "/img/login_logomwell.png";

  TRANSACTION_USER = "/img/transaction_user.png";
  GENERALI_LOGO = "/img/generali_logo.png";

  DEFAULT_SERVICE = "/img/default_service.png";
  DEFAULT_HOSPITAL = "/img/default_hospital.png";

  IC_DASH_1 = "/img/ic_dash_1.png";
  IC_DASH_2 = "/img/ic_dash_2.png";
  IC_DASH_3 = "/img/ic_dash_3.png";
  IC_DASH_4 = "/img/ic_dash_4.png";
  IC_DASH_5 = "/img/ic_dash_5.png";
  IC_DASH_6 = "/img/ic_dash_6.png";
  IC_DASH_7 = "/img/ic_dash_7.png";
  IC_DASH_8 = "/img/ic_dash_8.png";
  IC_DASH_9 = "/img/ic_dash_9.png";
  IC_DASH_10 = "/img/ic_dash_10.png";
  IC_DASH_11 = "/img/ic_dash_11.png";
  IC_DASH_12 = "/img/ic_dash_12.png";
  IC_DASH_13 = "/img/ic_dash_13.png";
  IC_DASH_14 = "/img/ic_dash_14.png";
  IC_DASH_15 = "/img/ic_dash_15.png";
  IC_DASH_16 = "/img/ic_dash_16.png";
  IC_DASH_17 = "/img/ic_dash_17.png";
  IC_DASH_18 = "/img/ic_dash_18.png";
  IC_DASH_19 = "/img/ic_dash_19.png";
  IC_DASH_20 = "/img/ic_dash_20.png";
  IC_DASH_21 = "/img/ic_dash_21.png";
  IC_DASH_22 = "/img/ic_dash_22.png";
  IC_DASH_23 = "/img/ic_dash_23.png";
  IC_DASH_24 = "/img/ic_dash_24.png";
  IC_DOCTOR = "/img/ic_doctor.png";
  IC_PATIENT = "/img/ic_patient.png";
  IC_DOWNLOAD = "/img/ic_download.png";
  IC_VIEW = "/img/ic_view.png";

  CHEVRON_LEFT = "/img/chevron_left.png";
  CHEVRON_RIGHT = "/img/chevron_right.png";
  DOCTOR_USER = "/img/doctor_user.png";
  CONSULT_PRICE = "/img/consult_price.png";
  PAYMENT_METHOD = "/img/payment_method.png";
  BOOKING_TYPE = "/img/booking_type.png";
  BOOKING_DATE = "/img/booking_date.png";
  BOOKING_TIME = "/img/booking_time.png";
  VIEW_BTN = "/img/view_btn.png";
  VIEW_BTN_1 = "/img/view_btn_1.png";
  EDIT_BTN_1 = "/img/edit_btn_1.png";
  SETTINGS = "/img/settings.svg";
  SETTINGS_DP = "/img/settings-dp.png";
  ELLIPSIS = "/img/ellipsis.png";
  MESSAGE = "/img/message.png";
  CALENDAR_BTN = "/img/calendar_btn.png";
  CALL_VIDEO = "/img/call_video.png";
  CALL_AUDIO = "/img/call_audio.png";
  CALL_PHONE = "/img/call_phone.png";
  CALL_NOTES = "/img/call_notes.png";
  PRESCRIPTION = "/img/prescription.png";
  USER_GFX = "/img/user_gfx.png";
  USER_GFX_2 = "/img/user_gfx2.png";
  USER_GROUP_PENDING = "/img/user_group_pending.png";
  USER_GROUP_DISAPPROVED = "/img/user_group_disapproved.png";
  USER_GROUP_APPROVED = "/img/user_group_approved.png";

  SAMPLE_PRODUCT = "/img/sample_product.png";

  DETAILS_ADDRESS = "/img/details_address.png";
  DETAILS_AGE = "/img/details_age.png";
  DETAILS_BIRTHDATE = "/img/details_birthdate.png";
  DETAILS_BLOODTYPE = "/img/details_bloodtype.png";
  DETAILS_BMI = "/img/details_bmi.png";
  DETAILS_GENDER = "/img/details_gender.png";
  DETAILS_HEIGHT = "/img/details_height.png";
  DETAILS_WEIGHT = "/img/details_weight.png";

  DETAIL_QUALIFICATION = "/img/detail_qualification.png";
  DETAILS_HOSPITAL = "/img/details_hospital.png";
  DETAILS_PRC = "/img/details_prc.png";
  DETAILS_SPECIALIZATION = "/img/details_specialization.png";
  DETAILS_HOSPITAL_ADDRESS = "/img/details_hospital_address.png";

  TRANSPARENT = "/img/transparent.png";
  TRANSACTION_ITEM = "/img/transaction_item.png";

  ECOMMERCE_LOGIN = "/img/ecommerce_login.png";

  COMMERCE_INQUIRY = "/img/commerce_inquiry.png";
  COMMERCE_INVENTORY = "/img/commerce_inventory.png";
  COMMERCE_PRODUCTS = "/img/commerce_products.png";
  COMMERCE_TRANSACTIONS = "/img/commerce_transactions.png";
  PRODUCT_BOX = "/img/product_box.png";

  INQUIRY = "/img/inquiry.png";
  LOADER = "/img/loader.png";

  SUCCESS = "/img/success.png";
  FAILED = "/img/failed.png";
  WARNING = "/img/warning.png";
  COMING_SOON = "/img/coming_soon.png";
  CLOSE = "/img/close.png";
  CLOSE_GRAY = "/img/CLOSE_GRAY.png";
  SEND = "/img/SEND.png";
  CHAT_ICON = "/img/chat_icon.png";

  ERROR_MINUS = "/img/error_minus.png";
  TRASH_BANNER = "/img/trash_banner.png";
  SUCCESS_BANNER = "/img/success_banner.png";
  WARNING_BANNER = "/img/warning_banner.png";
  FAILED_BANNER = "/img/failed_banner.png";
  PROCESS_FAILED_BANNER = "/img/process_failed_banner.png";

  GEAR_WARNING = "/img/gear_warning.png";

  LOADER_2 = "/img/loader.gif";

  EXPORT_BTN = "/img/export_btn.png";
  COMPLETE_BTN = "/img/complete_btn.png";
  REFUND_BTN = "/img/refund_btn.png";
  CORNER_EDIT_BTN = "/img/corner_edit.png";

  SYMBOL_MALE = "/img/symbol-male.png";
  SYMBOL_FEMALE = "/img/symbol-female.png";
  SYMBOL_MARRIAGE = "/img/symbol-marriage.png";

  QR_CODE_BG = "/img/qr_code_bg.png";
  SCAN_ID_BACK = "/img/scan_id_back.png";
  SCAN_ID_FRONT = "/img/scan_id_front.png";
  QR_CODE_JOIN = "/img/qr_code_join.png";

  SAVED_ICON = "/img/check-mark.png";
  X_ICON = "/img/x-mark.png";
  IMG_ICON = "/img/img-icon.png";
  CHECK_ICON = "/img/check_icon.png";
  APPOINTMENT_ICON = "/img/appointment_icon.png";

  TELEMED_STATUS = "/img/telemed_status.png";
  ADVOCACY_STATUS = "/img/advocacy_status.png";
  NO_OF_CONSULTS = "/img/no_of_consults.png";
  DOCTOR_TYPE = "/img/doctor_type.png";

  INFO_GENDER = "/img/add-info-gender.png";
  INFO_BIRTHDATE = "/img/add-info-birthdate.png";
  INFO_MED_SCHOOL = "/img/add-info-med_school.png";
  INFO_QUALI_DATE = "/img/add-info-quali_date.png";
  INFO_SOURCE = "/img/add-info-source.png";
  INFO_SPECIALTY = "/img/add-info-specialty.png";
  INFO_HOSPITAL = "/img/add-info-hospital.png";
  INFO_JOINED = "/img/add-info-joined_advo.png";
  INFO_TRAININGS = "/img/add-info-trainings.png";
  INFO_LANGUAGE = "/img/add-info-language.png";
  INFO_HOME = "/img/add-info-home.png";
  INFO_PRC_NUM = "/img/add-info-prc_num.png";
  INFO_PRC = "/img/add-info-prc_start.png";

  DROPDOWN_GREY = "/img/dropdown_grey.png";

  DP_NAV_ATTENDANCE = "/img/dp-attendance.svg";
  DOCTOR_ATTENDANCE = "/img/attendance.svg";
  DOCTOR_LEAVEREQUEST = "/img/leave.svg";
  DOCTOR_TIMELOGS = "/img/timelogs.png";
  DP_CALENDAR = "/img/DatePickerCalendarIcon.svg";
  DROPDOWN_GREY = "/img/dropdown_grey.png";

  CAMERA_ICON = "/img/camera_icon.png";
  CALENDAR_ICON = "/img/calendar_icon.png";
  GENDER_ICON = "/img/gender_icon.png";

  SYSTEM_NOTICE_BANNER = "/img/system_notice_banner.png";
  CONFIRMATION_BANNER = "/img/confirmation_banner.png";

  DEFAULT_MARKETING_BANNER ="/img/default_marketing_banner.png";
  FB_ICON = "/img/fb_icon.png";
  GOOGLE_ICON = "/img/google_icon.png";
  EMAIL_ICON = "/img/email_icon.png";
  APPLE_ICON = "/img/apple_icon.png";
  MWELL_LOGO_1 = "/img/mwell_logo_1.png";

  BG_BLUE_MWELL = "/img/bg-blue-mwell.png";

  
  SIXTY_DAY_RIBBON = "/img/free-60day-trial.svg";
  ICON_TELECONSULTATION = "/img/icon-teleconsultation.svg";
  ICON_EMS = "/img/icon-ems.svg";
  ICON_CROWN = "/img/icon-crown.svg"
  ICON_STARS = "/img/icon-stars.svg";
  ICON_MWELL_MD = "/img/icon-mwell-md.png";
  DEACTIVATE_ICON = "/img/deactivate_icon.png";
  CANCEL_ICON = "/img/cancel_icon.png";
  ACTIVATE_ICON = "/img/activate_icon.png";
}

export default Images;
