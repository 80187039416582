import { Button, Col, Row, Layout, Image, Typography, Badge } from 'antd';
import React, { Component } from 'react';
import styled from 'styled-components';
import UI from '../../shared/Components/UI/js';
import APIConstants from "../../shared/modules/APIConstants";
import DoctorsApi from "../../api_services/doctors";
import { mem } from "../../shared/custom-react-native";

const context = "onboard";
const Constants = new APIConstants();
export default class LoginSelect extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      remember_me: false,
      context: context,
    });
  }

  show_loading = (msg, isTransparent) => { 
    this.setState({ show_loading: true, loading_msg: msg, loading_opaque: false, }); 
    try {
      DoctorsApi.getMwellPlusSubscription().then((res) => {
        this.setState({...res}, () => {
          // console.log(this.state);
        });
      })
    } catch(err) {
      console.log("error:",err);
    }
  }; 
    
  hide_loading = () => { 
    this.setState({ show_loading: false, loading_msg: "", loading_opaque: true, });
  };

  handleTransit = (e) => {
    if(e === 'ems') {
      window.location.href = `${Constants.MWELLMD_WEBSITE_URL}/login?token=${mem.get('jwt_token')}`;
    } else {
      mem.save('doctorLoginSelected', "teleconsultation");
      const prcTextREminder = mem.get("prcTextREminder");
      const birTextReminder = mem.get("birTextReminder");

      if (prcTextREminder || birTextReminder) {
        this.props.history.push(
          "/" +
          this.state.context +
          "/dashboard?isPrcTextREminder=" +
          (prcTextREminder || "") +
          "&birTextReminder=" +
          (birTextReminder || "")
        );
      } else {
        window.location.href = '/onboard/dashboard'
      }
    }
  }

  render() {
    const { hasActiveSubscription, freeTrialConsumed } = this.state;
    
    return (
      <LoginStepLayout _this={this} className='login-select-bg'>
        <LoginContainer>
          <StyledRow>
            <StyledCol>
              <Image 
                height={70}
                preview={false}
                src={UI.ADVOCACY_LOGO_HOMEPAGE}
              />
              <FieldLabel>Kamusta ka Doc?</FieldLabel>
              <FieldLabel className='sub'>What would you like to do today?</FieldLabel>
            </StyledCol>
          </StyledRow>
          <Row>
            <StyledCol className='btn-container'>
              <StyledButton
                onClick={(e) => this.handleTransit('teleconsultation')}
                icon={<Image src={UI.ICON_TELECONSULTATION} preview={false} />} 
              >
                Teleconsultation
              </StyledButton>
              <FreeTrial text={(hasActiveSubscription || freeTrialConsumed) ? '' : <Image src={UI.SIXTY_DAY_RIBBON} preview={false} />}>
                <StyledButton
                  onClick={(e) => this.handleTransit('ems')} 
                  icon={<Image src={UI.ICON_EMS} preview={false} />} 
                >
                  Electronic Medical Records and Clinic Management System
                </StyledButton>
              </FreeTrial>
            </StyledCol> 
          </Row>
        </LoginContainer>
      </LoginStepLayout>
    );
  }
}

const LoginStepLayout = styled(Layout)`
  width: 100%;
  height: 100%;

  &.login-select-bg {
    background: url('/img/abstract-envelope.svg') no-repeat;
    background-size: cover;
  }
`;

const LoginContainer = styled(Layout)`
  width: 90%;
  max-width: 470px;
  max-height: 508px;
  padding: 20px 30px 50px;
  margin: auto;
  background-color: #FFF;
  border-radius: 4px;
  display: flex;
  justify-content: center;
`;

const StyledRow = styled(Row)`
  justify-content: center;
`;

const StyledCol = styled(Col)`
  text-align: center;
  margin-bottom: 1em;

  &.btn-container {
    display: block;
    margin: 0 auto;
  }
`;

const FieldLabel = styled(Typography.Paragraph)`
  font-family: Poppins;
  font-size: 1.1rem;
  font-weight: bold;
  color: #000;
  margin: 1em 0 0 !important;

  &.sub {
    font-size: 0.9rem;
    font-weight: lighter;
    margin: 0 !important;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: start;
  align-items: center;
  border-radius: 5px;
  border-width: 2px;
  border-color: #035099;
  height: max-content;
  min-height: 100px;
  width: 100%;
  max-width: 371px;
  margin-bottom: 1.5em;
  padding: 15px;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: bold;
  color: #000;
  
  .ant-image {
    flex-shrink: 0;
    flex-basis: 60px;
    
    img {
      max-height: 49px;
    }
  }
  
  span {
    display: inline-flex;
    margin-left: 1.2em;
    flex-wrap: wrap;
    white-space: normal;
    text-align: left;
  }
`;

const FreeTrial = styled(Badge.Ribbon)`
  top: -0.7em;
  right: -21px !important;
  background-color: initial;

  .ant-ribbon-corner {
    display: none;
  }
`;