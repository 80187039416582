import UI from "../shared/Components/UI/js";
import ServiceApi from "./service";

const mapPatients = (data) => {
    return data.map((item, index) => {
        const name = UI.DataHelper.carespan.get_name(item.name);

        item.fullname = name.trim().toLowerCase();
        item.timestamp = new Date(item.createdAt).getTime();
        return item;
    });
}

class DoctorsApi {

    baseURL = 'admin';

    async getDoctors (params) {
        const response = await ServiceApi.get(this.baseURL+'/doctors', params);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.data = mapPatients(data.data);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getMemberships () {
        const response = await ServiceApi.get(`${this.baseURL}/doctors/memberships`);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getDoctorMemberships (id, params) {
        const response = await ServiceApi.get(`${this.baseURL}/doctors/${id}/memberships`, params);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async updateDoctorRegistrationStatus (id, params) {
        const response = await ServiceApi.put(this.baseURL+`/doctors/approved/${id}`, params);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            // data.data = mapPatients(data.data
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getDoctorFiles (params) {
        const response = await ServiceApi.get(this.baseURL+`/doctors/doctor-files`, params);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            // data.data = mapPatients(data.data
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getListNotificationCMS (id, params) {
        const response = await ServiceApi.get(`doctors_onboarding/notification-hub/${id}`, params);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            // data.data = mapPatients(data.data
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async createNotificationCMS (id, payload) {

        const response = await ServiceApi.post(`doctors_onboarding/notification-hub/${id}`, payload);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return Promise.reject({
                message: response.data.m
            });
        }
    }

    async updateNotificationCMS (id, payload) {
        const response = await ServiceApi.put(`doctors_onboarding/notification-hub/${id}`, payload);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async deleteNotificationCMS (id, userId) {
        const response = await ServiceApi.delete(`doctors_onboarding/notification-hub/${userId}/${id}`);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getDoctorBirFormsByAdmin (id) {
        const response = await ServiceApi.get(`admin/doctors/${id}`);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getDoctorScheduleAvailability (id) {
        const response = await ServiceApi.get(`doctors_onboarding/doctors/slots/${id}`);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getMwellPlusSubscription () {
        const response = await ServiceApi.get(`doctors_onboarding/doctors/mwellmd-subscription/`);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async rescheduleDoctorAppointment (id, payload) {
        const response = await ServiceApi.post(`doctors_onboarding/doctors/reschedule/${id}`, payload);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async AcceptDeclineReScheduleAppointment(appointmentId, status) {
        const response = await ServiceApi.get(`doctors_onboarding/doctors/reschedule/${status}/${appointmentId}`);

        if (response?.status === 200) {
          const data = UI.extract_data(response);
          data.status = 200;
          return data;
        } else {
          return {
            status: response.status,
            data: response.data.d,
            message: response.data.m
          }
        }
      }

      async rescheduleDoctorAppointmentRead (appointmentId, payload) {
        const response = await ServiceApi.put(`doctors_onboarding/doctors/reschedule/read/${appointmentId}`, payload);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
      }
}

export default new DoctorsApi()
