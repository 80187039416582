import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import APIConstants from "../../../shared/modules/APIConstants";
// import moment from "moment";
import { Row, Col, Button, Image } from "antd";
import styled from "styled-components";
import UI from "../../../shared/Components/UI/js";
import Container from "../../../ComponentsFC/ui/Container";
import ContainerLabelPrimary from "../../../ComponentsFC/ui/ContainerLabelPrimary";
import FieldBirthDate from "../../../icons/FieldBirthDate";
import FieldDateIcon from "../../../icons/FieldDate";
import FieldGenderIcon from "../../../icons/FieldGender";
import FieldHeightIcon from "../../../icons/FieldHeight";
import FieldWeightIcon from "../../../icons/FieldWeight";
import FieldBMIIcon from "../../../icons/FieldBMI";
import FieldHeartIcon from "../../../icons/FieldHeart";
import FieldAddressIcon from "../../../icons/FieldAddress";
import { doctorNameColor, fieldLabelColor, isMobileScreen, patientDetailHeaderColor } from "../../../constants";
// import toTitleCase from "../../../../utils/toTitleCase";
// import ServiceAdminDoctorAccountsApi from "../../../../api_services/Admin/doctor-accounts-api";
// import FileDownload from "js-file-download";
// import Loading from "../../../../modules/_components/Loading";

const Constants = new APIConstants();
const imageStorage = Constants.STORAGE_BASE_URL;
const imageContainer = "doctors-onboarding";
// const new_src =
//         Constants.STORAGE_BASE_URL +
//         "?path=" +
//         path +
//         "&container=" +
//         container;

const PatientReferralDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const [details, setDetails] = useState();

  useEffect(() => {
    // console.log("LOCATION: ", location.state.data);
    setDetails(location.state.data);
  }, [location.state.data]);

  const removePlus63 = (phoneNumber) => {
    const hasPlus63Beginning =
      phoneNumber?.charAt(0) === "+" ||
      phoneNumber?.charAt(1) === "6" ||
      phoneNumber?.charAt(2) === "3";

    if (hasPlus63Beginning) {
      return phoneNumber.replace("+63", "");
    } else {
      return phoneNumber;
    }
  };

  useEffect(() => {
    removePlus63("+639056188980");
  }, []);

  const patientDetails = [
    {
      icon: <FieldBirthDate
        style={{ marginRight: 20, verticalAlign: 0 }}/>,
      title: "Birthday",
      data: details?.patientInfo[0]?.birthday ?? "--"
    },
    {
      icon: <FieldDateIcon
        style={{ marginRight: 20, verticalAlign: 0 }}/>,
      title: "Age",
      data: details?.patientInfo[0]?.age ?? "--"
    },
    {
      icon: <FieldGenderIcon
        style={{ marginRight: 20, verticalAlign: 0 }}/>,
      title: "Gender",
      data: details?.patientInfo[0]?.gender ?? "--"
    },
    {
      icon: <FieldHeightIcon
        style={{ marginRight: 20, verticalAlign: 0 }}/>,
      title: "Height",
      data: details?.patientInfo[0]?.height ?? "--"
    },
    {
      icon: <FieldWeightIcon
        style={{ marginRight: 20, verticalAlign: 0 }}/>,
      title: "Weight",
      data: details?.patientInfo[0]?.weight ?? "--"
    },
    {
      icon: <FieldBMIIcon
        style={{ marginRight: 20, verticalAlign: 0 }}/>,
      title: "BMI",
      data: details?.patientInfo[0]?.bmi ?? "--"
    },
    {
      icon: <FieldHeartIcon
        style={{ marginRight: 20, verticalAlign: 0 }}/>,
      title: "Blood Type",
      data: details?.patientInfo[0]?.bloodType ?? "--"
    },
    {
      icon: <FieldAddressIcon
        style={{ marginRight: 20, verticalAlign: 0 }}/>,
      title: "Street Address",
      data: details?.patientInfo[0]?.streetAddress ?? "--"
    },
    {
      icon: "",
      title: "Barangay",
      data: details?.patientInfo[0]?.barangay ?? "--"
    },
    {
      icon: "",
      title: "City",
      data: details?.patientInfo[0]?.city ?? "--"
    },
    {
      icon: "",
      title: "Province",
      data: details?.patientInfo[0]?.province ?? "--"
    },
    {
      icon: "",
      title: "Zip Code",
      data: details?.patientInfo[0]?.zipCode ?? "--"
    },
  ]

  const mappedPatientDetails = patientDetails.map((detail, index) => {
    const emptyIcon = detail.icon === "";
    const floatData = detail.title === "Height" || detail.title === "Weight";

    return (
      <li key={index} className="flex flex-row items-center text-base lg:text-lg font-medium capitalize py-5 lg:py-2">
        <div style={{ color: fieldLabelColor }} className="flex-1 whitespace-nowrap">
          <span>{detail.icon}</span>
          <span className={emptyIcon && "ml-10"}>{detail.title}</span>
        </div>
        <div className="flex-1">
          <span className="">{floatData ? parseFloat(detail.data).toFixed(2) : detail.data}</span>
        </div>
      </li>
    )
  })

  return (
    <>
      {/* <Loading
        visible={isScreenLoading}
        title={""}
        text={""}
        modal_opaque={false}
      /> */}
      <Container>
        {/* ANTD ROW 24 GRID LAYOUT 8x8x8 (span) = 24 */}
          <div className="flex flex-row items-center gap-3">
            <div className="flex flex-row gap-4 items-center">
              <StyledButton onClick={() => history.push("/onboard/referral")}>
                <span className="text-xl">{'<'}</span>
              </StyledButton>
            </div>
            <h1 style={{ color: patientDetailHeaderColor }}       className="font-medium text-2xl m-0">
              Patient Details
            </h1>
          </div>
          <div className="flex lg:flex-row flex-col w-full">
            <div className="flex lg:flex-row lg:gap-5 flex-col items-center lg:w-1/2">
                <StyledImage
                  src={
                    details?.patientInfo[0]?.avatarLink
                      ? `${imageStorage}?path=${details?.patientInfo[0]?.avatarLink}&container=${imageContainer}`
                      : UI.DEFAULT_AVATAR_PATIENT
                    }
                  style={{paddingTop: 20, paddingBottom: 20}}
                  preview={false}
                  width={isMobileScreen ? "80%" : 200}
                />
                <div className="flex flex-col justify-center lg:items-start items-center">
                  <span className="text-xl font-medium">
                    {/* {`${details?.name[0]?.given.join(" ")} ${
                        details?.name[0]?.family
                      }`} */}
                    {`${details?.patientInfo[0]?.fullname ?? "No name filled"}`}
                  </span>
                <div style={{ color: doctorNameColor }} className="mb-[10px] font-medium text-lg flex-2">
                  {/* {details?.telecom[1]?.value ?? "--"} */}
                  <span className="break-all">{`${details?.patientInfo[0]?.email ?? "No email filled"}`}</span>
                </div>
              <StyledDoctorsContactDetails>
                {details?.patientInfo[0]?.phoneNumber
                  ? `+63 ${removePlus63(
                      details?.patientInfo[0]?.phoneNumber
                    )}` ?? "--"
                  : "No contact number filed"}
              </StyledDoctorsContactDetails>
            </div>
          </div>
          <div className="w-full lg:flex lg:flex-row">
            {isMobileScreen ? 
            (
              <ul>
                {mappedPatientDetails}
              </ul>
            ) : (
              <>
                <ul className="flex-1 xl:ml-5">
                {mappedPatientDetails.slice(0, 6)}
                </ul>
                <ul className="flex-1">
                  {mappedPatientDetails.slice(6, 13)}
                </ul>
              </>
            )}
          </div>
        </div>
      </Container>

      <Container style={{ width: "46%", minWidth: "46%" }}>
        <ContainerLabelPrimary>Referral Details</ContainerLabelPrimary>
        <FieldLabel style={{ fontSize: 20, marginBottom: 20, marginTop: 5 }}>
          Referral Type
        </FieldLabel>
        <FieldValue style={{ marginBottom: 30 }}>{`${
          details?.type === "teleconsult"
            ? "Teleconsult"
            : details?.type === "face-to-face"
            ? "F2F Appointment"
            : "--"
        }`}</FieldValue>
        <FieldLabel style={{ fontSize: 20, marginBottom: 20 }}>
          Reason for Referral
        </FieldLabel>
        <FieldValue style={{ marginBottom: 30 }}>
          {`${details?.reasonForReferral ?? "No referral reason filed"}`}
        </FieldValue>
        <FieldLabel style={{ fontSize: 20, marginBottom: 20 }}>
          Chief Complaint
        </FieldLabel>
        <FieldValue style={{ marginBottom: 30 }}>
          {`${details?.chiefComplaint ?? "No chief complaint filed"}`}
        </FieldValue>
        <FieldLabel style={{ fontSize: 20, marginBottom: 20 }}>
          Pertinent Information
        </FieldLabel>
        <FieldValue style={{ marginBottom: 30 }}>
          {`${
            details?.pertinentInformation ?? "No pertinent information filed"
          }`}
        </FieldValue>
      </Container>
    </>
  );
};

export default PatientReferralDetails;

// CSS Styled Components ----------------------

const StyledImage = styled(Image)`
  border-radius: 12px;
`;

const StyledDoctorsContactDetails = styled.p`
  margin-bottom: 10px;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 500;
  color: #898989;
`;
const StyledButton = styled(Button)`
  height: 42px;
  background-color: #0e4d94;
  border: 2px solid #0e4d94;
  border-radius: 12px;

  font-size: 18px;
  font-family: Poppins;
  font-weight: 500; // medium
  color: #ffffff;

  &:hover,
  &:focus,
  &:active {
    background-color: #0e4d94;
    color: #ffffff;
  }
`;

const FieldLabel = styled.p`
  margin-bottom: 6.5px;

  font-family: Poppins;
  font-size: 18px; //18px
  font-weight: 500;
  color: #4fc2ed;
`;

const FieldValue = styled.p`
  margin-bottom: 6.5px;

  font-family: Poppins;
  font-size: 18px; //18px
  font-weight: 500;
  color: #333333;
`;
