import React, { useCallback, useState, useEffect } from "react";
import DataTable from "../../ComponentsFC/DataTable";
import UI from "../../shared/Components/UI/js";
import moment from "moment";
import styled, { css } from "styled-components";
import { Row, Col, Button, Input, Select, Typography, DatePicker } from "antd";
import DropdownIcon from "../../icons/Dropdown";
import SearchIcon from "../../icons/Search";
import ServiceDoctorsApi from "../../api_services/doctors";
import TouchableOpacity, { Image, View } from "../../shared/custom-react-native";
import mem from "../../shared/Components/Memory/js";
import DatePickerCalendarIcon from "../../icons/DatePickerCalendar";

import DoctorNotificationCMSModal from "./Modal";
import { DeleteConfirmation } from "../_components/Modal";

const EmailTable = ({ _this }) => {
  const { width } = _this.state;
  const BREAKPOINT_WIDTH = 720;
  const isMobile = width <= BREAKPOINT_WIDTH;

  const [tableData, setTableData] = useState([]);
  const [searchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableEntries, setTableEntries] = useState(30);
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [filterType, setFilterType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [applyDateFilter, setApplyDateFilter] = useState(false);

  const getNotificationCMS = useCallback(async () => {

    const data = mem.get("onboard_data");
    const user = JSON.parse(data);
    const id = user?.id

    try {

      if(!applyDateFilter && startDate && endDate) return;

      setIsLoading(true);

      let params = {};

      if(startDate) {
        params.startDate = startDate
      }

      if(endDate) {
        params.endDate = endDate
      }

      if (searchQuery) {
        params.search = searchQuery;
      }

      if (filterType) {
        params.filterType = filterType
      }

      if (currentPageNum) {
        params.page = currentPageNum;
        params.limit = tableEntries;
      }

      ServiceDoctorsApi.getListNotificationCMS(id, params).then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          let data = res.data;
          setTotalRecords(res.total);
          setTableData(data.map((obj) => ({ ...obj, key: obj._id })));
        }
      });

    } catch (err) {
      // Handle Errors Here
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNum, tableEntries, searchQuery, filterType, startDate, endDate, applyDateFilter]);

  useEffect(() => {
    getNotificationCMS();
  }, [getNotificationCMS]);

  const handlePaginateTable = (pageNum) => {
    setCurrentPageNum(pageNum);
  };

  const tableColumnHeader = [
    {
      title: "Fullname",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Date Added",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => {
        return moment(record.createdAt).format('M/DD/YYYY')
      }
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      key: "contactNumber"
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (_id, item) => {

        return (
          <View style={{ flexDirection: "row", gap: 5 }}>
            <TouchableOpacity 
              onClick={() => {
                _this.show_modal(
                  <DoctorNotificationCMSModal
                    _this={_this}
                    actionType="Edit"
                    item={item}
                    onDismiss={() => {
                      _this.hide_modal();
                    }}
                    getNotificationCMS={getNotificationCMS}
                  />
              )}}
            >
              <Image
                height={30}
                width={30}
                tintColor={"transparent"}
                source={UI.EDIT_BTN_1}
              />
            </TouchableOpacity>

            <TouchableOpacity 
              onClick={() => {
                _this.show_modal(
                  <DeleteConfirmation
                    text="Are you sure you want to delete this email?"
                    onCancel={() => {
                      _this.hide_modal();
                    }}
                    onDelete={() => handleDelete(item._id)}
                  />
              )}}
            >
              <Image
                height={30}
                width={30}
                tintColor={"transparent"}
                source={UI.DELETE_BTN}
              />
            </TouchableOpacity>
          </View>
        )
      },
      width: 200,
    },
  ];

  const handleChangeNumOfEntries = (value) => {
    setCurrentPageNum(1);
    setTableEntries(Number(value));
  };

  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPageNum(1);
    setTableEntries(30);
  };

  const handleDelete = (id) => {

    const data = mem.get("onboard_data");
    const user = JSON.parse(data);
    const userId = user?.id;

    ServiceDoctorsApi.deleteNotificationCMS(id, userId).then((res) => {
      setIsLoading(false);
      _this.hide_modal();
      if (res.status === 200) {
        let data = res.data;
        setTotalRecords(res.total);
        setTableData(data.map((obj) => ({ ...obj, key: obj._id })));
      }
    });
  }

  const handleClearViaSelectField = (fieldName) => {
    // if (fieldName === "type") {
    //   setSearchResults([]);
    // }
  }

  const handleFilterByShiftDateRange = (dates) => {
    if(dates) {

      let startDate = dates[0].format('MM-DD-YYYY');
      let endDate = dates[1].format('MM-DD-YYYY');
      
      setApplyDateFilter(false)
      setStartDate(startDate)
      setEndDate(endDate)
    } else {
      setStartDate(null)
      setEndDate(null)
    }
  };

  const EntriesSelector = () => (
    <div style={{ 
      display: 'flex', 
      alignItems: 'center',
      width: isMobile ? '100%' : 'auto'
    }}>
      <FieldLabel style={{ 
       whiteSpace: isMobile ? 'nowrap' : 'normal',
       marginRight: isMobile ? '16px' : '30px'
      }}>Only Show</FieldLabel>
      <StyledSelect
        getPopupContainer={(trigger) => trigger.parentNode}
        defaultValue="30"
        style={{
          width: isMobile ? '100%' : 160,
        }}
        suffixIcon={<DropdownIcon />}
        onChange={handleChangeNumOfEntries}
      >
        <Select.Option value="30">30 Entries</Select.Option>
        <Select.Option value="100">100 Entries</Select.Option>
        <Select.Option value={totalRecords}>All Entries</Select.Option>
      </StyledSelect>
    </div>
  );

  return (
    <>
      <FilterBox>
        <Title level={5}>Notification Hub</Title>
        <Toolbar gutter={14} >
          <ToolItem span={isMobile ? 24 : 8}
          style={{ marginTop: isMobile ? "16px" : "0" }}>
            <StyledFieldLabel>Select Type</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Select Type"
              style={{
                width: "100%",
              }}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={(value) => setFilterType(value)}
              onClear={() => handleClearViaSelectField("type")}
            >
              <Select.Option title="Gender" value="contact">
                Contact Reference
              </Select.Option>
              <Select.Option title="Gender" value="email">
                Email Reference
              </Select.Option>
              <Select.Option title="Gender" value="all">
                All
              </Select.Option>
            </StyledSelect>
          </ToolItem>
          <ToolItem 
            span={isMobile ? 24 : 16}
            style={{ marginTop: isMobile ? "16px" : "0" }}
          >
            <StyledFieldLabel>Date Range</StyledFieldLabel>
            <FlexRow style={{ 
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: isMobile ? 'flex-end' : 'center'
             }}>
              <StyledRangePicker
                separator={<DatePickerCalendarIcon />}
                suffixIcon={<DatePickerCalendarIcon />}
                ranges={{
                  Today: [moment(), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                }}
                onChange={handleFilterByShiftDateRange}
                style={{ width: isMobile ? '100%' : 'auto' }}
              />
              <StyledButton
                type="primary"
                style={{ 
                  margin: isMobile ? "16px 0 0 0" : "0 0 0 20px"
                }}
                onClick={() => {
                  getNotificationCMS()
                  setApplyDateFilter(true)
                }}
              >
                Apply Date Filter
              </StyledButton>
            </FlexRow>
          </ToolItem>
        </Toolbar>
      </FilterBox>
      <TableBox>
        <Toolbar>
          <EntriesSelector />
          <StyledSearch
            placeholder="Enter Keyword"
            allowClear
            suffix={<SearchIcon />}
            value={searchQuery}
            onChange={(e) => {
              handleChangeSearchQuery(e);
            }}
            onPressEnter={getNotificationCMS}
            style={{ 
              width: isMobile ? '100%' : 200,
              marginLeft: isMobile ? 0 : 'auto',
              marginTop: isMobile ? "16px" : "0"
            }}
          />
          <StyledButton 
            onClick={() => 
              _this.show_modal(
                <DoctorNotificationCMSModal
                  _this={_this}
                  type="email"
                  actionType="Add"
                  onDismiss={() => _this.hide_modal()}
                  getNotificationCMS={getNotificationCMS}
                />
              )}
              type="primary"
              style={{ 
                margin: isMobile ? "16px 0 0 0" : "0 0 0 20px"
              }}
            >
            Add New
          </StyledButton>
        </Toolbar>
        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={searchResults.length > 0 ? searchResults : tableData}
          defaultPageSize={30}
          pageSizeOptions={["30", "100", totalRecords]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isLoading}
          scroll={{
            x: 600,
          }}
        />
      </TableBox>
    </>
  );
};

export default EmailTable;

// CSS Styled Components ----------------------

export const media = {
  mobile: (styles) => css`
    @media (max-width: 720px) {
      ${styles}
    }
  `,
};
const FilterBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #bfbfbf29;
  border-radius: 12px;
  padding: 33px 0 0 0;
  margin-bottom: 30px;
`;

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
`;

const Toolbar = styled(Row)`
  display: flex;
  align-items: center;
  padding: ${props => props.isMobile ? '16px' : '0 35px 33px 35px'};
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
  justify-content: flex-end;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

const ToolItem = styled(Col)`
  position: relative;
  width: 100%;
`;

const Title = styled(Typography.Title)`
font-family: Poppins;
font-weight: 500 !important; //medium
font-size: 1.4375rem !important; // 15px
line-height: 1.4375rem !important; // 23px
letter-spacing: 0 !important;
color: #004f99 !important;
padding-left: 35px;
margin-bottom: 0.5rem !important;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

const StyledButton = styled(Button)`
  height: 50px;
  border-radius: 12px;
  border: none;
  background-color: #0e4d94;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;

// const LinkButton = styled(Button)`
//   flex: 1;
//   padding: 0;
//   height: 100%;
// `;

const StyledSearch = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px;
  margin-left: auto;
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
  }
`;

const FlexRow = styled(Row)`
  flex-direction: row;
  z-index: 1;
  align-items: center;
`;

const StyledRangePicker = styled(DatePicker.RangePicker)`
  flex-direction: row;
  border-radius: 12px;
  border: 1px solid transparent;
  box-shadow: none !important;
  height: 50px;
  &:hover {
    border-color: transparent !important;
  }
  .ant-picker-input {
    border: 2px solid #d9d9d9;
    border-radius: 12px;
    padding-left: 10px;
    padding-right: 10px;
    width: 191px;
    height: 55px;
  }
  .ant-picker-range-separator {
    display: block !important; //Need this bcoz the this selector css.css interferes globally
    margin-left: -43px; //To insert built-in icon inside input field
    margin-right: 16px;
  }
  .ant-picker-suffix {
    margin-left: -35px; //To insert built-in icon inside input field
  }
  .ant-picker-active-bar {
    display: none;
  }
  .ant-picker-clear {
    /* display: none; */
    right: 18px;
  }

  ${media.mobile(css`
    width: 100%;
    height: 100%;
    padding: 0px;
    display: block;
    flex-direction: column;
    position: relative;
    gap: 16px;

    .ant-picker-input {
      width: 100%;
      height: 100%;
      padding: 12px;
      position: relative; 
      margin-bottom: 16px;
    }
    .ant-picker-range-separator {
      position: absolute;
      top: 8%;
      right: -10px;
    }
    .ant-picker-suffix {
      position: absolute;
      top: 58%;
      right: 14px;
  `)}
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #65d1ff;
  margin-bottom: 10px !important;
`;
