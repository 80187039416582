import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button, Row } from "antd";
import UI from "../../../../shared/Components/UI/js";
import CustomModal from "../../../ui/modals/CustomModal";
import TrashBinIcon from "../../../../icons/TrashBinModal";
import ProcessFailedModal from "../../../ui/modals/ProcessFailedModal";
import SuccessModal from "../../../ui/modals/SuccessModal";
import WarningIcon from "../../../../icons/WarningIcon";

import ServiceDPSettingsApi from "../../../../api_services/Doctors-Portal/settings";
import SuccessModalCheckIcon from "../../../../icons/SuccessModalCheck";
import { mem } from "../../../../shared/custom-react-native";

const DoctorAppointmentPendingList = ({ list }) => {
  return (
    <div className="my-4 flex items-center justify-center text-center m-auto gap-28">
      <div>
        <div className="mb-6 text-blue-1100">Date Of Appointment</div>
        {list.map(item => (
          <div>{UI.momentPHTimezone(item.start).format('MMMM DD, YYYY')}</div>
        ))}
      </div>
      <div>
        <div className="mb-6 text-blue-1100">Patient Name</div>
        {list.map(item => (
          <div>{item.patient}</div>
        ))}
      </div>
    </div>
  )
}

const AccountDeletion = (props) => {
  const [isFailedModalOpen, setIsFailedModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isAccountDeletedModalOpen, setIsAccountDeletedModalOpen] = useState(false);
  const [isAppointmentCancelledSuccess, setIsAppointmentCancelledSuccess] = useState(false);
  const [isProceedToAccountDeletionSuccess, setIsProceedToAccountDeletionSuccess] = useState(false);
  const [pendingAppointments, setPendingAppointments] = useState(null);
  const [isFailedCancelAppointments, setIsFailedCancelAppointments] = useState(false);

  const handleCloseFailedModal = () => {
    setIsFailedModalOpen(false);
  };

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleAccountDeletedModal = () => {
    setIsAccountDeletedModalOpen(false);
  };

  const handleCancelAppointments = async () => {
    const doctor = JSON.parse(mem.get("user_doctor"));

    try {
      const apiResponse = await ServiceDPSettingsApi.cancelDoctorAppointments(doctor.id, {
        appointments: pendingAppointments
      });

      if (apiResponse?.status === 200 || apiResponse?.status === 201) {
        setIsAppointmentCancelledSuccess(true)
        setIsFailedModalOpen(false)
      } else {
        setIsFailedModalOpen(false);
        setIsFailedCancelAppointments(true);
      }

    } catch (error) {
      console.log(error);
    }
  }

  const handleDeleteAccount = async () => {
    try {
      let payload = {
        //key: value
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      setIsConfirmationModalOpen(false);
      props?.onScreenIsLoading(true);
      const apiResponse = await ServiceDPSettingsApi.deleteDoctorAccount(payload, config);
      props?.onScreenIsLoading(false);

      if (apiResponse?.status === 200 || apiResponse?.status === 201) {
        setIsAccountDeletedModalOpen(true);
      } else if (
        apiResponse?.status === 400 &&
        apiResponse?.data?.withUpcomingAppointments
      ) {
        setPendingAppointments(apiResponse?.data?.withUpcomingAppointments)
        setIsFailedModalOpen(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <ProcessFailedModal
        title={"Action Failed"}
        banner={<WarningIcon />}
        description={<>{"We cannot proceed with your account deletion. Our system noticed that you have an upcoming appointment/s. Would you like to cancel all appointments?"}</>}
        extrajsx={<DoctorAppointmentPendingList list={pendingAppointments}/>}
        visible={isFailedModalOpen}
        width={850}
        onCancel={handleCloseFailedModal} // x and cancel button
        footer={[
          <FooterWrapper>
            <StyledUpperFooter>
              <StyledButtonNo 
                onClick={handleCloseFailedModal} 
                style={{
                  width: "340px",
                  marginRight: "1rem"
                }}
              >
                CANCEL
              </StyledButtonNo>
              <StyledButtonYesOrOk
                onClick={handleCancelAppointments}
              >
                CANCEL ALL APPOINTMENTS
              </StyledButtonYesOrOk>
            </StyledUpperFooter>
          </FooterWrapper>
        ]}
      />

      <ProcessFailedModal 
        title={"Cancel Appointments Failed"}
        banner={<WarningIcon />}
        description={<>{"You cannot proceed with your account deletion due to upcoming consultation/s which cannot be cancelled due to business rule. Contact support for more information."}</>}
        extrajsx={<></>}
        visible={isFailedCancelAppointments}
        width={600}
        onCancel={() => setIsFailedCancelAppointments(false)}
        footer={[
          <FooterWrapper>
            <StyledUpperFooter>
              <StyledButtonYesOrOk
                onClick={() => setIsFailedCancelAppointments(false)}
              >
                OK
              </StyledButtonYesOrOk>
            </StyledUpperFooter>
          </FooterWrapper>
        ]}
      />

      <StyledSuccessModal 
        visible={isAppointmentCancelledSuccess}
        title={"Success!"}
        buttonText={"PROCEED TO ACCOUNT DELETION"}
        banner={<SuccessModalCheckIcon />}
        description={<>Appointments successfully cancelled</>}
        width={600}
        onCancel={() => setIsAppointmentCancelledSuccess(false)} // x and cancel button
        onOk={handleDeleteAccount}
      />

      <SuccessModal 
        visible={isProceedToAccountDeletionSuccess}
        title={"Success!"}
        banner={<SuccessModalCheckIcon />}
        description={
          <div>
            <p>Appointments successfully cancelled</p>
            <p className="mt-4">Thank you for using mWell Doctors Application.</p>
          </div>
        }
        width={600}
        buttonText={"OK"}
        onOk={() => setIsProceedToAccountDeletionSuccess(false)} // x and cancel button
      />

      <CustomModal
        title={"Account Deleted"}
        headercolor={"#b90c0c"}
        banner={<TrashBinIcon />}
        description={
          <StyledDiv>
            <p>{"Account has been deleted!"}</p>
            <p style={{ marginBottom: 0 }}>
              {"Thank you for using mWell Doctors Application."}
            </p>
          </StyledDiv>
        }
        buttonText={"OK"}
        buttoncolor={"#004f99"}
        visible={isAccountDeletedModalOpen}
        width={530}
        onCancel={handleAccountDeletedModal} // x and cancel button
        footer={[
          <StyledButtonYesOrOk onClick={() => {
            handleAccountDeletedModal();
            UI.logout();
          }}>
            OK
          </StyledButtonYesOrOk>,
        ]}
      />
      
      <CustomModal
        title={"Confirmation"}
        headercolor={"#035099"}
        banner={<TrashBinIcon />}
        description={"Would you like to proceed with your account deletion"}
        buttonText={"YES"}
        buttoncolor={"#004f99"}
        visible={isConfirmationModalOpen}
        width={530}
        // onOk={handleCloseModal}
        onCancel={handleCloseConfirmationModal} // x and cancel button
        footer={[
          <FooterWrapper>
            <StyledUpperFooter>
              <StyledButtonYesOrOk onClick={handleDeleteAccount}>
                Yes
              </StyledButtonYesOrOk>
              <StyledButtonNo onClick={handleCloseConfirmationModal}>
                No
              </StyledButtonNo>
            </StyledUpperFooter>
            <StyledLowerFooter>
              {"Read more about our Terms and Conditions"}
              <Row style={{ alignItems: "center" }}>
                {"by clicking this"}
                <StyledLink
                  to={{
                    pathname: "https://www.mwell.com.ph/terms-and-conditions",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LINK
                </StyledLink>
              </Row>
            </StyledLowerFooter>
          </FooterWrapper>,
        ]}
      />

      <StyledP>If you delete your account:</StyledP>
      <StyledUl>
        <StyledLi>{"You can't regain access once it's deleted."}</StyledLi>
        <StyledLi>
          {"You can't access all records that you have with your account."}
        </StyledLi>
        <StyledLi>
          {
            "Some information, such as the information available on those e-documents that you have issued your patients during you past appointments with them, will still be available in the platform."
          }
        </StyledLi>
        <StyledLi>
          {
            "Copies of some material may remain in our database but are disassociated from personal identifiers."
          }
        </StyledLi>
      </StyledUl>
      <StyledButton onClick={() => setIsConfirmationModalOpen(true)}>
        Continue
      </StyledButton>
    </>
  );
};

export default AccountDeletion;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  margin: auto;
`;

const FooterWrapper = styled.div``;

const StyledUpperFooter = styled(Row)`
  align-items: center;
  justify-content: center;

  @media(max-width: 768px) {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
`;

const StyledLowerFooter = styled(Row)`
  align-items: center;
  justify-content: center;
  width: 480px;
  margin: 13px auto auto auto;

  font-size: 20px;
  font-family: Poppins;
  font-weight: 500;
  color: #02CBFB;

  @media(max-width: 768px) {
    font-size: 14px;
    width: auto;
  }
`;

const StyledLink = styled(Link)`
  padding: 0;
  margin-left: 4px;

  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  color: #02CBFB;
`;

const StyledButtonYesOrOk = styled(Button)`
  font-size: 23px;
  font-family: Poppins;
  font-weight: 500; //medium
  color: #ffffff;
  background-color: #004f99;

  border: 2px solid #004f99;
  border-radius: 12px;
  min-width: 162px;
  height: 74px;

  @media(max-width: 768px) {
    padding: 12px;
    font-size: 14px;
    height: auto;
    width: auto;
    min-width: auto;
    flex: 1;
  }

  &:hover {
    background-color: #004f99;
    color: #ffffff;
  }

  &:focus {
    background-color: #004f99;
    color: #ffffff;
  }

  &:active {
    background-color: #004f99;
    color: #ffffff;
  }
`;

const StyledButtonNo = styled(Button)`
  margin-left: 20px !important;
  font-size: 23px;
  font-family: Poppins;
  font-weight: 500; //medium
  color: #004f99;
  background-color: #ffffff;

  border: 2px solid #004f99;
  border-radius: 12px;
  width: 162px;
  height: 74px;

  @media(max-width: 768px) {
    padding: 12px;
    font-size: 14px;
    height: auto;
    width: auto;
    margin-left: 0px !important;
    flex: 1;
  }

  &:hover {
    color: #004f99;
    background-color: #ffffff;
  }

  &:focus {
    color: #004f99;
    background-color: #ffffff;
  }

  &:active {
    color: #004f99;
    background-color: #ffffff;
  }
`;



const StyledP = styled.p`
  text-align: left;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500; // medium
  letter-spacing: 0px;
  color: #2e2e2e;
  opacity: 1;
  margin-bottom: 30px;
`;

const StyledUl = styled.ul`
  margin-left: 34px;
  margin-bottom: 30px;
`;

const StyledLi = styled.li`
  list-style: disc;
  text-align: left;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500; // medium
  letter-spacing: 0px;
  color: #2e2e2e;
  opacity: 1;
  margin-bottom: 15px;

  ::marker {
    color: #c0c0c0;
  }
`;

const StyledButton = styled(Button)`
  width: 244px;
  height: 67px;
  border-radius: 14px;
  border: none;
  color: #ffffff;
  background-color: #0e4d94;
  font-family: Poppins;
  font-weight: 500;
  font-size: 1.25rem; // 20px
  line-height: 1.4375rem; // 23px
  margin-left: 0;
  margin-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
    color: #0e4d94;
  }
  &:focus {
    background-color: #0e4d94;
    color: #ffffff;
  }
`;

const StyledSuccessModal = styled(SuccessModal)`
  .ant-modal-footer {
    .ant-btn-primary {
        width: auto;
    }
  }`
